// import { Link } from 'gatsby'
import React from "react";
import useSiteMetadata from "./SiteMetadata";
import styled from "styled-components";
import { SIDE_MARGIN } from "./GlobalStyles";

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 10px ${SIDE_MARGIN}px;
  background: black;
  color: white;

  font-weight: 700;
`;

const Navbar: React.FC = () => {
  const { title } = useSiteMetadata();

  return (
    <NavbarContainer role="navigation" aria-label="main-navigation">
      {title}
    </NavbarContainer>
  );
};

export default Navbar;
