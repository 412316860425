import { graphql, useStaticQuery } from "gatsby";
import { Site_Metadata_QueryQuery } from "../../graphql-types";

const useSiteMetadata = () => {
  const { site } = useStaticQuery<Site_Metadata_QueryQuery>(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  return site?.siteMetadata || {title: "Unknown title", description: "Unknown Description"}
};

export default useSiteMetadata;
