import { withPrefix } from "gatsby";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Navbar from "./Navbar";
import useSiteMetadata from "./SiteMetadata";
import GlobalStyles, { SIDE_MARGIN } from "./GlobalStyles";
import styled from "styled-components";
import Div100vh from "react-div-100vh";

interface Props {
  title?: Maybe<string>;
  children: ReactNode;
  ogData?: {
    type?: string;
    image?: string;
  };
  noMargin?: boolean;
}

const Body = styled(Div100vh).attrs({ style: { minHeight: "100rvh" } })`
  min-height: 100vh;
  padding-top: 38px; /* Navbar height */
  display: flex;
  flex-direction: column;
`;

const BodyWithMargin = styled(Body)`
  padding-left: ${SIDE_MARGIN}px;
  padding-right: ${SIDE_MARGIN}px;
`;

const Layout: React.FC<Props> = ({ title, children, ogData, noMargin }) => {
  const { title: siteTitle, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title || siteTitle}</title>
        <meta name="description" content={description || ""} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content={ogData?.type || "website"} />
        <meta property="og:title" content={title || siteTitle || ""} />
        <meta property="og:url" content="https://erickson.works" />
        <meta
          property="og:image"
          content={ogData?.image || `${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <GlobalStyles />
      <Navbar />
      {noMargin ? (
        <Body>{children}</Body>
      ) : (
        <BodyWithMargin>{children}</BodyWithMargin>
      )}
      <Footer />
    </div>
  );
};

export default Layout;

export const ConstrainedWidth = styled.div`
  max-width: 1400px;
  margin: auto;
`;
